.App-header {
  background-color: #d8d433;
  height: 64px;
  padding-top: 10px;
}
.minima-logo {
  vertical-align: middle;
  color: white;
  font-weight: bold;
  margin-top: 5px;
  margin-left: 20px;
}
.spinner {
  margin-top: 15%;
  margin-left: 50%;
}
.backarrow {
  cursor: pointer;
  margin-top: 5%;
}
.rc-menu li:first-child {
  border-bottom: 1px solid #ccc !important;
}
.rc-menu li:not(:first-child) {
  margin-top: 7px;
}